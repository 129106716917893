#sectionHero {
  .heroVisual {
    @if $type =="" {
    }
    @if $type ==sp {
      img {
        width: 100%;
        max-width: inherit;
      }
    }
    @if $type ==pc {
    }
  }

  .heroCatch {
    @if $type =="" {
      position: relative;
    }
    @if $type ==sp {
      padding-block: 57px 70px;
    }
    @if $type ==pc {
      padding-block: 108px 73px;
    }

    &:before,
    &:after {
      @if $type =="" {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        pointer-events: none;
      }
      @if $type ==sp {
      }
      @if $type ==pc {
        width: 38.02vw;
        max-width: 730px;
        aspect-ratio: 1/1;
      }
    }

    &:before {
      @if $type =="" {
        left: 0;
      }
      @if $type ==sp {
        width: 32.5vw;
        max-width: 244px;
        height: 460px;
        background: url(../images2024/home/catch_bg_left_sp.webp) no-repeat;
        background-size: contain;
      }
      @if $type ==pc {
        background: url(../images2024/home/catch_bg_left.webp) no-repeat;
        background-size: contain;
      }
    }

    &:after {
      @if $type =="" {
        right: 0;
      }
      @if $type ==sp {
        width: 54.67vw;
        max-width: 410px;
        height: 460px;
        background: url(../images2024/home/catch_bg_right_sp.webp) no-repeat;
        background-size: contain;
      }
      @if $type ==pc {
        background: url(../images2024/home/catch_bg_right.webp) no-repeat;
        background-size: contain;
      }
    }

    .heroCatchText {
      @if $type =="" {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }
    }

    .heroCatchTitle {
      @if $type =="" {
        @include fontYu;
        font-weight: 300;
        line-height: 1;
        letter-spacing: 0.03em;
        background: rgb(255,114,114);
        background: linear-gradient(90deg, rgba(255,114,114,1) 0%, rgba(196,31,99,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

      }
      @if $type ==sp {
        font-size: 42px;
      }
      @if $type ==pc {
        font-size: 83px;
      }
    }

    .heroCatchStrong {
      @if $type =="" {
        // @include fontYu;
        // font-weight: bold;
        // line-height: 1;
        // letter-spacing: 0.06em;

        // background: rgb(232,83,143);
        // background: linear-gradient(280deg, rgba(232,83,143,1) 0%, rgba(196,31,99,1) 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        aspect-ratio: 204/32;
      }
      @if $type ==sp {
        margin-top: 22px;
        width: 120px;
        aspect-ratio: 120/19;
        //font-size: 19px;
      }
      @if $type ==pc {
        margin-top: 30px;
        aspect-ratio: 204/32;
        //font-size: 32px;
      }
    }

    .heroCatchNote {
      @if $type =="" {
        @include fontYu;
        color: #000000;
        font-weight: 500;
      }
      @if $type ==sp {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 22px;
        font-size: 14px;
        line-height: 2;
      }
      @if $type ==pc {
        margin-top: 40px;
        font-size: 20px;
        text-align: center;
      }
    }
    
  }
}

#sectionMc,
#sectionService,
#sectionVoice {
  .titleHeader {
    @if $type =="" {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @if $type ==sp {
    }
    @if $type ==pc {
    }

    .title {
      @if $type =="" {
        line-height: 1.2;
      }
      @if $type ==sp {
        margin-top: 6px;
        font-size: 13px;
      }
      @if $type ==pc {
        margin-top: 10px;
        font-size: 18px;
      }
    }

    .titleSub {
      @if $type =="" {
        @include fontEn;
        line-height: 1.4;
        background: rgb(232,83,143);
        background: linear-gradient(270deg, rgba(232,83,143,1) 0%, rgba(196,31,99,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @if $type ==sp {
        font-size: 35px;
        letter-spacing: 0.02em;
      }
      @if $type ==pc {
        font-size: 60px;
        letter-spacing: 0.08em;
      }
    }
  }
}


#sectionMc {
  @if $type =="" {
  }
  @if $type ==sp {
  }
  @if $type ==pc {
    padding-block: 68px 73px;
  }

  .inner {
    @if $type =="" {
    }
    @if $type ==sp {
      padding-inline: 0;
      overflow: hidden; 
    }
    @if $type ==pc {
      max-width: 1800px;
    }
  }

  .titleHeader {
    @if $type =="" {
    }
    @if $type ==sp {
      @include maxSP;
    }
    @if $type ==pc {
    }
  }

  .mcLead {
    @if $type =="" {
      text-align: center;
    }
    @if $type ==sp {
      max-width: 300px;
      padding: 0 15px;
      margin-top: 10px;
      margin-inline: auto;
      font-size: 14px;
      line-height: 1.75;
    }
    @if $type ==pc {
      margin-top: 25px;
      font-size: 20px;
    }
  }

  .mcSlide {
    @if $type =="" {
      position: relative;
    }
    @if $type ==sp {
      margin-top: 25px;
    }
    @if $type ==pc {
      padding-inline: 60px;
      margin-top: 50px;
    }

    
		.splide__arrows {
			@if $type =="" {}

			@if $type ==sp {
				display: none !important;
			}

			@if $type ==pc {
			}

			.splide__arrow {
				@if $type =="" {
					border: none;
					border-radius: 50%;
          background: transparent;
					position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: .2s filter;
          
          &:disabled {
            filter: grayscale(100%);
            cursor: inherit;
          }

					&.splide__arrow--prev {
						transform: scale(-1, 1) translateY(-61px);
            left: 0;

					}

					&.splide__arrow--next {
						transform: scale(1, 1) translateY(-61px);
            right: 0;
					}
				}

				@if $type ==sp {}

				@if $type ==pc {
					width: 40px;
					height:40px;
          &:not(:disabled) {
            &:focus,
            &:hover {
              &:before {
                transform: translateX(5px);
              }
            }
          }

				}

				&:before {
					@if $type =="" {
						content: "";
						display: block;
						background: url(../images2024/common/icon_arrow_big_pink.svg) no-repeat center;
						background-size: contain;
            background-position: right center;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						margin: auto;
					}

					@if $type ==sp {}

					@if $type ==pc {
						width: 40px;
						height: 45px;
						transition: .2s transform;
					}
				}

				svg {
					@if $type =="" {
						display: none;
					}

					@if $type ==sp {}

					@if $type ==pc {}
				}
			}
		}

    .splide__arrows {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }

      .splide__arrow {
        @if $type =="" {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        @if $type ==sp {
        }
        @if $type ==pc {
        }
      }
    }

    .splide__pagination {
      @if $type =="" {
      }
      @if $type ==sp {
        padding-inline: 15px;
        margin-top: 15px;
      }
      @if $type ==pc {
        margin-top: 22px;
      }

      > li {
        @if $type =="" {
        }
        @if $type ==sp {
          margin-inline: 4px;
        }
        @if $type ==pc {
          margin-inline: 7px;
        }

        button {
          @if $type =="" {
            appearance: none;
            border: none;
            border-radius: 9999px;
            background: #d8d8d8;

            &.is-active {
              background: $c_base;
            }
          }
          @if $type ==sp {
            width: 7px;
            height: 7px;
          }
          @if $type ==pc {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    .splide__slide {
      @if $type =="" {
        position: relative;
      }
      @if $type ==sp {
        width: calc(47.73vw + 6px) !important;
        padding-inline: 3px;
      }
      @if $type ==pc {
        padding-inline: 6px;
      }

      a {
        @if $type =="" {
          display: block;
        }
        @if $type ==sp {
        }
        @if $type ==pc {
          &:hover,
          &:focus-visible {
            .mcSlideThumb {
              img {
                transform: scale(1.1);
              }
            }
          }
        }

        .mcSlideThumb {
          @if $type =="" {
            overflow: hidden;
          }
          @if $type ==sp {
            border-radius: 5px;
          }
          @if $type ==pc {
            border-radius: 10px;
            aspect-ratio: 295/369;
          }

          img {
            @if $type =="" {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            @if $type ==sp {
            }
            @if $type ==pc {
              transition: .2s transform;
            }
          }
        }

        .mcSlideText {
          @if $type =="" {
          }
          @if $type ==sp {
            margin-top: 13px;
            text-align: center;
          }
          @if $type ==pc {
            margin-top: 20px;
            text-align: center;
          }

          .mcSlideName {
            @if $type =="" {
              font-weight: bold;
            }
            @if $type ==sp {
              font-size: 16px;
            }
            @if $type ==pc {
              font-size: 17px;
            }
          }

          .mcSlideNameEn {
            @if $type =="" {
              @include fontEn;
              color: $c_base;
              letter-spacing: 0.16em;
            }
            @if $type ==sp {
              margin-top: 2px;
              font-size: 11px;
            }
            @if $type ==pc {
              font-size: 16px;
            }
          }
        }

      }
    }
  }

  .btnWrap {
    @if $type =="" {
    }
    @if $type ==sp {
      margin-top: 27px;
    }
    @if $type ==pc {
    }
  }

}

#sectionService {
  @if $type =="" {
    position: relative;
  }
  @if $type ==sp {
    padding-block: 75px 90px;
    overflow: hidden;
  }
  @if $type ==pc {
    padding-block: 73px 74px;
  }


  .serviceInner {
    @if $type =="" {
    }
    @if $type ==sp {
      @include maxSP;
    }
    @if $type ==pc {
      width: 100%;
      max-width: 1640px;
      padding-inline: 40px;
      margin-inline: auto;
    }
  }

  .titleHeader {
    @if $type =="" {
    }
    @if $type ==sp {
    }
    @if $type ==pc {
      align-items: flex-start;
      
    }

    .title {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
        font-size: 18px;
      }
    }
    
    .titleSub {
      @if $type =="" {
        line-height: 1.2;
      }
      @if $type ==sp {
        font-size: 50px;
        letter-spacing: 0.04em;
      }
      @if $type ==pc {
        font-size: 110px;
        letter-spacing: 0.02em;
      }
    }
  }

  .serviceContetntItem {
    @if $type =="" {
    }
    @if $type ==sp {
    }
    @if $type ==pc {
      position: relative;
    }

    &:nth-child(1) {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
        margin-bottom: 50px;
      }

      .serviceContentText {
        @if $type =="" {
        }
        @if $type ==sp {
        }
        @if $type ==pc {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 40%;
          max-width: 480px;
          margin-right: 60%;
          margin-left: auto;
          min-height: 580px;
          letter-spacing: 0.04em;
          word-break: keep-all;
        }
        @if $type ==tb {
          width: 100%;
          max-width: inherit;
          margin-right: inherit;
          min-height: inherit;
        }

        .serviceContentTitle {
          @if $type =="" {
          }
          @if $type ==sp {
            margin-top: 25px;
          }
          @if $type ==pc {
            margin-top: 50px;
          }
        }
 
        .serviceContentThumb {
          @if $type =="" {
          }
          @if $type ==sp {
          }
          @if $type ==pc {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin-block: auto;
          }
          @if $type ==tb {
            position: inherit;
            top: inherit;
            bottom: inherit;
            right: inherit;
            margin-block: 30px 0;
          }
        }

      .serviceContentThumb {
        @if $type =="" {
          overflow: hidden;
        }
        @if $type ==sp {
          margin-top: 14px;
          border-radius: 5px;
          aspect-ratio: 720/477;
        }
        @if $type ==pc {
          display: flex;
          align-items: center;
          width: 56%;
          height: 516px;
          border-radius: 10px;
        }
        @if $type ==tb {
          width: 100%;
          min-height: inherit;
          margin-top: 30px;
          aspect-ratio: 720/477;
        }

        img {
          @if $type =="" {
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
          }
          @if $type ==sp {
          }
          @if $type ==pc {
          }
        }
      }


      }
    }

    + .serviceContetntItem  {
      @if $type =="" {
      }
      @if $type ==sp {
        margin-top: 45px;
      }
      @if $type ==pc {
        margin-top: 55px;
      }
      @if $type ==tb {
        margin-top: 50px;
      }
    }

    &:not(:nth-child(1)) {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
        display: flex;
        align-items: center;
        min-height: 366px;
      }
      @if $type ==tb {
        flex-direction: column;
        min-height: inherit;
      }

      &:nth-child(even) {
        @if $type =="" {
        }
        @if $type ==sp {
        }
        @if $type ==pc {
          padding-left: 50%;
        }
        @if $type ==tb {
          padding-left: 0;
        }

        .serviceContentThumb {
          @if $type =="" {
          }
          @if $type ==sp {
          }
          @if $type ==pc {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin-block: auto;
          }
          @if $type ==tb {
            position: inherit;
            top: inherit;
            bottom: inherit;
            left: inherit;
            margin-block: 30px 0;
          }
        }
        
      }

      &:nth-child(odd) {
        @if $type =="" {
        }
        @if $type ==sp {
        }
        @if $type ==pc {
          padding-right: 50%;
        }
        @if $type ==tb {
          padding-right: 0;
        }

        .serviceContentThumb {
          @if $type =="" {
          }
          @if $type ==sp {
          }
          @if $type ==pc {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin-block: auto;
          }
          @if $type ==tb {
            position: inherit;
            top: inherit;
            bottom: inherit;
            right: inherit;
            margin-block: 30px 0;
          }
        }
        
      }

      .serviceContentThumb {
        @if $type =="" {
          overflow: hidden;
        }
        @if $type ==sp {
          margin-top: 14px;
          border-radius: 5px;
        }
        @if $type ==pc {
          display: flex;
          align-items: center;
          width: 50%;
          min-height: 366px;
          border-radius: 10px;
        }
        @if $type ==tb {
          width: 100%;
          min-height: inherit;
          margin-top: 30px;
        }

        img {
          @if $type =="" {
          }
          @if $type ==sp {
          }
          @if $type ==pc {
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
          }
        }
      }

      .serviceContentText {
        @if $type =="" {
        }
        @if $type ==sp {
        }
        @if $type ==pc {
          width: 59.35%;
          max-width: 440px;
          margin-inline: auto;
          padding-block: 20px;
        }
        @if $type ==tb {
          width: 100%;
          max-width: inherit;
          padding-block: 0;
        }
      }
    }

    .serviceContentText {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }

      .serviceContentTitle {
        @if $type =="" {
          font-weight: bold;
        }
        @if $type ==sp {
          font-size: 21px;
        }
        @if $type ==pc {
          font-size: 30px;
          line-height: 1.2;
        }

        span {
          @if $type =="" {
          }
          @if $type ==sp {
            font-size: 12px;
          }
          @if $type ==pc {
            font-size: 16px;
          }
        }
      }

      .serviceContentNote {
        @if $type =="" {
        }
        @if $type ==sp {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.85;
        }
        @if $type ==pc {
          margin-top: 18px;
          font-size: 17px;
          line-height: 2.35;
        }
      }

      * + .btnWrap {
        @if $type =="" {
        }
        @if $type ==sp {
          margin-top: 24px;
        }
        @if $type ==pc {
          margin-top: 40px;
        }
      }

    }
  }
}

#sectionVoice {
  @if $type =="" {
  }
  @if $type ==sp {
    padding-block: 0 60px;
    padding-inline: 15px;
    background: url(../images2024/home/voice_bg_sp.webp) no-repeat center top;
    background-size: cover;
  }
  @if $type ==pc {
    max-width: 1800px;
    padding: 75px 40px 70px;
    margin-inline: auto;
    background: url(../images2024/home/voice_bg.webp) no-repeat center bottom;
    background-size: cover;
  }

  .voiceLead {
    @if $type =="" {
      text-align: center;
    }
    @if $type ==sp {
      margin-top: 11px;
      font-size: 14px;
      line-height: 1.75;
    }
    @if $type ==pc {
      margin-top: 27px;
      font-size: 20px;
    }
  }

  .voiceSlide {
    @if $type =="" {
    }
    @if $type ==sp {
      margin-top: 28px;
    }
    @if $type ==pc {
      padding-inline: 57px;
      margin-top: 52px;
    }

    .splide__arrows {
			@if $type =="" {}

			@if $type ==sp {
				display: none !important;
			}

			@if $type ==pc {
			}

			.splide__arrow {
				@if $type =="" {
					border: none;
					border-radius: 50%;
          background: transparent;
					position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: .2s filter;
          
          &:disabled {
            filter: grayscale(100%);
            cursor: inherit;
          }

					&.splide__arrow--prev {
						transform: scale(-1, 1);
            left: 0;

					}

					&.splide__arrow--next {
						transform: scale(1, 1);
            right: 0;
					}
				}

				@if $type ==sp {}

				@if $type ==pc {
					width: 40px;
					height:40px;
          &:not(:disabled) {
            &:focus,
            &:hover {
              &:before {
                transform: translateX(5px);
              }
            }
          }

				}

				&:before {
					@if $type =="" {
						content: "";
						display: block;
						background: url(../images2024/common/icon_arrow_big_pink.svg) no-repeat center;
						background-size: contain;
            background-position: right center;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						margin: auto;
					}

					@if $type ==sp {}

					@if $type ==pc {
						width: 40px;
						height: 45px;
						transition: .2s transform;
					}
				}

				svg {
					@if $type =="" {
						display: none;
					}

					@if $type ==sp {}

					@if $type ==pc {}
				}
			}
		}

    .splide__arrows {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }

      .splide__arrow {
        @if $type =="" {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        @if $type ==sp {
        }
        @if $type ==pc {
        }
      }
    }

    .splide__track {
      @if $type =="" {
      }
      @if $type ==sp {
        overflow: visible;
      }
      @if $type ==pc {
      
      }
    }

    .splide__pagination {
      @if $type =="" {
      }
      @if $type ==sp {
        margin-top: 15px;
      }
      @if $type ==pc {
        margin-top: 22px;
      }

      > li {
        @if $type =="" {
        }
        @if $type ==sp {
          margin-inline: 4px;
        }
        @if $type ==pc {
          margin-inline: 7px;
        }

        button {
          @if $type =="" {
            appearance: none;
            border: none;
            border-radius: 9999px;
            background: #fff;

            &.is-active {
              background: $c_base;
            }
          }
          @if $type ==sp {
            width: 7px;
            height: 7px;
          }
          @if $type ==pc {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    .splide__slide {
      @if $type =="" {
      }
      @if $type ==sp {
        padding: 20px 27px;
        border-radius: 5px;
        box-shadow: 3px 3px 17px rgba(255, 214,218,.3);
        background: rgba(255,255,255,.7);

        &:nth-child(n + 3) {
          display: none;
        }

        + .splide__slide {
          margin-top: 15px;
        }
      }
      @if $type ==pc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(33.3% - 20px) !important;
        padding: 40px 45px 34px;
        margin-inline: 10px;
        border-radius: 10px;
        background: rgba(255,255,255,.6);
      }
      @if $type ==tb {
        width: 100% !important;
        margin-inline: 0;
      }


      .voiceListTitle {
        @if $type =="" {
          font-weight: bold;
        }
        @if $type ==sp {
          font-size: 15px;
          line-height: 1.53;
        }
        @if $type ==pc {
          min-height: 3em;
          font-size: 18px;
          line-height: 1.55;
        }
      }

      .voiceListNote {
        @if $type =="" {
        }
        @if $type ==sp {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.7;
        }
        @if $type ==pc {
          margin-top: 23px;
          font-size: 16px;
          line-height: 2;
        }
      }

      .voiceListName {
        @if $type =="" {
          text-align: right;
          font-weight: 500;
        }
        @if $type ==sp {
          margin-top: 7px;
          font-size: 12px;
        }
        @if $type ==pc {
          padding-top: 14px;
          margin-top: auto;
          margin-left: auto;
          font-size: 16px;
        }
      }
    }

  }
}

#sectionBlog,
#sectionChannel,
#sectionFaq {
  @if $type =="" {
  }
  @if $type ==sp {
  }
  @if $type ==pc {
  }

  .titleHeader {
    @if $type =="" {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @if $type ==sp {
    }
    @if $type ==pc {
    }

    .title {
      @if $type =="" {
        line-height: 1.2;
      }
      @if $type ==sp {
        margin-top: 5px;
        font-size: 13px;
      }
      @if $type ==pc {
        margin-top: 20px;
        font-size: 18px;
      }
    }

    .titleSub {
      @if $type =="" {
        @include fontEn;
        line-height: 1.4;
        background: rgb(232,83,143);
        background: linear-gradient(270deg, rgba(232,83,143,1) 0%, rgba(196,31,99,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @if $type ==sp {
        font-size: 35px;
      }
      @if $type ==pc {
        font-size: 40px;
      }
    }
  }
}

#sectionBlog {
  @if $type =="" {
  }
  @if $type ==sp {
    padding-block: 82px 68px;
  }
  @if $type ==pc {
    padding: 114px 40px 114px;
    max-width: 1800px;
    margin-inline: auto;
    background: url(../images2024/home/blog_bg.webp) no-repeat center;
    background-size: cover;
  }

  .inner {
    @if $type =="" {
    }
    @if $type ==sp {
    }
    @if $type ==pc {
      padding-inline: 0;
    }
  }

  .blogSlide {
    @if $type =="" {
    }
    @if $type ==sp {
      margin-top: 8px;
    }
    @if $type ==pc {
      padding-inline: 50px;
      margin-top: 27px;
    }
  }

  .splide__arrows {
    @if $type =="" {}

    @if $type ==sp {
      display: none !important;
    }

    @if $type ==pc {
    }

    .splide__arrow {
      @if $type =="" {
        border: none;
        border-radius: 50%;
        background: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: .2s filter;
        
        &:disabled {
          filter: grayscale(100%);
          cursor: inherit;
        }

        &.splide__arrow--prev {
          transform: scale(-1, 1) translateY(-77px);
          left: 0;

        }

        &.splide__arrow--next {
          transform: scale(1, 1) translateY(-77px);
          right: 0;
        }
      }

      @if $type ==sp {}

      @if $type ==pc {
        width: 40px;
        height:40px;

        &:not(:disabled) {
          &:focus,
          &:hover {
            &:before {
              transform: translateX(5px);
            }
          }
        }

        
      }

      &:before {
        @if $type =="" {
          content: "";
          display: block;
          background: url(../images2024/common/icon_arrow_big_pink.svg) no-repeat center;
          background-size: contain;
          background-position: right center;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }

        @if $type ==sp {}

        @if $type ==pc {
          width: 40px;
          height: 45px;
          transition: .2s transform;
        }
      }

      svg {
        @if $type =="" {
          display: none;
        }

        @if $type ==sp {}

        @if $type ==pc {}
      }
    }
  }

  .splide__arrows {
    @if $type =="" {
    }
    @if $type ==sp {
    }
    @if $type ==pc {
    }

    .splide__arrow {
      @if $type =="" {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }
    }
  }

  .splide__pagination {
    @if $type =="" {
    }
    @if $type ==sp {
      margin-top: 15px;
    }
    @if $type ==pc {
      margin-top: 22px;
    }

    > li {
      @if $type =="" {
      }
      @if $type ==sp {
        margin-inline: 4px;
      }
      @if $type ==pc {
        margin-inline: 7px;
      }

      button {
        @if $type =="" {
          appearance: none;
          border: none;
          border-radius: 9999px;
          background: #d8d8d8;

          &.is-active {
            background: $c_base;
          }
        }
        @if $type ==sp {
          width: 7px;
          height: 7px;
        }
        @if $type ==pc {
          width: 9px;
          height: 9px;
        }
      }
    }
  }


  .blogList {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {
    }

    >li {
      @if $type =="" {}

      @if $type ==sp {
        +li {
          border-top: 1px solid #cacaca;
        }
      }

      @if $type ==pc {
        width: 33.3% !important;
        padding-inline: 15px;
      }

      @if $type ==tb {
        width: 50% !important;
        padding-inline: 15px;
      }

      a {
        @if $type =="" {}

        @if $type ==sp {
          display: flex;
          align-items: center;
          padding-block: 17px;
        }

        @if $type ==pc {
          display: block;

          &:hover,
          &:focus-visible {
            .blogListThumb img {
              transform: scale(1.1);
            }
          }
        }

        .blogListThumb {
          @if $type =="" {
            overflow: hidden;
          }

          @if $type ==sp {
            width: 40.29%;
            aspect-ratio: 278/170;
            border-radius: 5px;
          }

          @if $type ==pc {
            aspect-ratio: 380/232;
            border-radius: 10px;
          }

          img {
            @if $type =="" {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }

            @if $type ==sp {}

            @if $type ==pc {
              transition: .2s transform;
            }
          }
        }

        .blogListText {
          @if $type =="" {}

          @if $type ==sp {
            width: 50.7%;
            margin-inline: auto;
          }

          @if $type ==pc {}

          .blogListDate {
            @if $type =="" {
              color: #888888;
            }

            @if $type ==sp {
              font-size: 11px;
              letter-spacing: 0.1em;
            }

            @if $type ==pc {
              margin-top: 20px;
              font-size: 15px;
              letter-spacing: 0.1em;
            }
          }

          .blogListTitle {
            @if $type =="" {
              overflow: hidden;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            @if $type ==sp {
              font-size: 14px;
              line-height: 1.57;
            }

            @if $type ==pc {
              margin-top: 2px;
            }
          }

        }

      }
    }
  }

  // .blogList {
  //   @if $type =="" {}

  //   @if $type ==sp {}

  //   @if $type ==pc {
  //     display: flex;
  //     flex-wrap: wrap;
  //   }

  //   >li {
  //     @if $type =="" {}

  //     @if $type ==sp {
  //       +li {
  //         border-top: 1px solid #cacaca;
  //       }
  //     }

  //     @if $type ==pc {
  //       width: calc(33.3% - 20px);

  //       &:not(:nth-child(3n)) {
  //         margin-right: 30px;
  //       }

  //     }

  //     a {
  //       @if $type =="" {}

  //       @if $type ==sp {
  //         display: flex;
  //         align-items: center;
  //         padding-block: 17px;
  //       }

  //       @if $type ==pc {
  //         display: block;

  //         &:hover,
  //         &:focus-visible {
  //           .blogListThumb img {
  //             transform: scale(1.1);
  //           }
  //         }
  //       }

  //       .blogListThumb {
  //         @if $type =="" {
  //           overflow: hidden;
  //         }

  //         @if $type ==sp {
  //           width: 40.29%;
  //           aspect-ratio: 278/170;
  //           border-radius: 5px;
  //         }

  //         @if $type ==pc {
  //           aspect-ratio: 380/232;
  //           border-radius: 10px;
  //         }

  //         img {
  //           @if $type =="" {
  //             object-fit: cover;
  //             width: 100%;
  //             height: 100%;
  //           }

  //           @if $type ==sp {}

  //           @if $type ==pc {
  //             transition: .2s transform;
  //           }
  //         }
  //       }

  //       .blogListText {
  //         @if $type =="" {}

  //         @if $type ==sp {
  //           width: 50.7%;
  //           margin-inline: auto;
  //         }

  //         @if $type ==pc {}

  //         .blogListDate {
  //           @if $type =="" {
  //             color: #888888;
  //           }

  //           @if $type ==sp {
  //             font-size: 11px;
  //             letter-spacing: 0.1em;
  //           }

  //           @if $type ==pc {
  //             margin-top: 20px;
  //             font-size: 15px;
  //             letter-spacing: 0.1em;
  //           }
  //         }

  //         .blogListTitle {
  //           @if $type =="" {
  //             overflow: hidden;
  //             display: -webkit-box;
  //             text-overflow: ellipsis;
  //             -webkit-box-orient: vertical;
  //             -webkit-line-clamp: 2;
  //           }

  //           @if $type ==sp {
  //             font-size: 14px;
  //             line-height: 1.57;
  //           }

  //           @if $type ==pc {
  //             margin-top: 2px;
  //           }
  //         }

  //       }

  //     }
  //   }
  // }
}

#sectionChannel {
  @if $type =="" {
  }
  @if $type ==sp {
    padding-block: 0 33px;
  }
  @if $type ==pc {
    padding-block: 0 57px;
  }

  .titleHeader {
    @if $type =="" {
    }
    @if $type ==sp {
    }
    @if $type ==pc {
    }

    .title {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }
    }

    .titleSub {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
        margin-top: 0;
      }
    }
  }

  .channelList {
    @if $type =="" {
    }
    @if $type ==sp {
      width: calc(100% - 50px);
      margin-inline: auto;
      margin-top: 30px;
    }
    @if $type ==pc {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
    }

    > li {
      @if $type =="" {
        line-height: 1;
        overflow: hidden;
      }
      @if $type ==sp {
        + li {
          margin-top: 16px;
        }
      }
      @if $type ==pc {
        width: calc(50% - 23px);

        &:nth-child(odd) {
          margin-right: auto;
        }

        &:nth-child(n + 3) {
          margin-top: 46px;
        }
      }

      iframe {
        @if $type =="" {
          width: 100%;
          height: 100%;
          aspect-ratio: 16/9;
        }
        @if $type ==sp {
          border-radius: 5px;
        }
        @if $type ==pc {
          border-radius: 10px;
        }
      }
    }
  }
}

#sectionFaq {
  @if $type =="" {
  }
  @if $type ==sp {
    padding-block: 33px 67px;
  }
  @if $type ==pc {
    padding-block: 57px 110px;
  }

  .faqContent {
    @if $type =="" {
    }
    @if $type ==sp {
    }
    @if $type ==pc {
      display: flex;
      justify-content: space-between;
    }

    .titleHeader {
      @if $type =="" {
      }
      @if $type ==sp {
      }
      @if $type ==pc {
        align-items: flex-start;
      }
    }

    .qaList {
      @if $type =="" {
      }
      @if $type ==sp {
        margin-top: 25px;
      }
      @if $type ==pc {
        width: calc(100% - 17.2%);
      }
      @if $type ==tb {
        width: calc(100% - 27.2%);
      }

      >li {
        @if $type =="" {
          border: 1px solid #cacaca;
          position: relative;
        }
    
        @if $type ==sp {
          border-radius: 10px;
        }
    
        @if $type ==pc {
          border-radius: 15px;
          transition: .2s border-color;
  
          &:hover,
          &:focus-visible {
            border-color: $c_base;
          }
        }

        &:before {
          @if $type =="" {
            content: "Q";
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 9999px;
            line-height: 1;
            background: #c41f63;
            position: absolute;
            pointer-events: none;
          }
          @if $type ==sp {
            width: 23px;
            height: 23px;
            padding-bottom: 3px;
            left: 12px;
            top: 16px;
          }
          @if $type ==pc {
            width: 32px;
            height: 32px;
            padding-bottom: 3px;
            left: 24px;
            top: 24px;
            margin-block: auto;
          }
        }

        +li {
          @if $type =="" {}
    
          @if $type ==sp {
            margin-top: 10px;
          }
    
          @if $type ==pc {
            margin-top: 14px;
          }
        }
    
        .qaTitle {
          @if $type =="" {
          }
          @if $type ==sp {
          }
          @if $type ==pc {
          }
    
          button {
            @if $type =="" {
              appearance: none;
              display: block;
              width: 100%;
              border: none;
              @include fontUsu;
              background: none;
              color: $c_text;
              text-align: left;
              cursor: pointer;
    
              &[aria-expanded=true] {
                .qaIcon {
                  transform: rotate(-180deg);
                }
              }
            }
    
            @if $type ==sp {
              padding: 16px 40px 16px 45px;
              font-size: 14px;
              line-height: 1.6;
              font-weight: 500;
              transition: .2s opacity;
            }
    
            @if $type ==pc {
              padding: 28px 50px 28px 90px;
              font-size: 17px;
              font-weight: 500;
            }

            .qaIcon {
              @if $type =="" {
                display: block;
                background: url(../images2024/common/icon_arrow_anchor.svg) no-repeat center;
                background-size: contain;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: .2s transform;
              }
    
              @if $type ==sp {
                width: 12px;
                right: 15px;
              }
    
              @if $type ==pc {
                width: 12px;
                height: 12px;
                right: 25px;
              }
    
            }
          }
        }
    
        .qaContent {
          @if $type =="" {
            border-top: 1px solid #cacaca;
    
            &[aria-hidden=true] {
              height: 0;
              opacity: 0;
              visibility: hidden;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
            }
    
            position: relative;
            transition: padding 0.3s,
            height 0.3s,
            opacity 0.3s,
            visibility 0.3s;
          }
    
          @if $type ==sp {
            width: calc(100% - 95px);
            margin-right: 40px;
            margin-left: 45px;
            padding: 16px 0 16px 0;
            font-size: 13px;
            line-height: 1.92;

          }
    
          @if $type ==pc {
            width: calc(100% - 140px);
            padding: 20px 0 20px 0;
            margin-right: 50px;
            margin-left: 90px;
          }

          &:before {
            @if $type =="" {
              content: "A.";
              display: flex;
              align-items: center;
              justify-content: center;
              padding-left: .3em;
              color: #fff;
              border-radius: 9999px;
              line-height: 1;
              color: $c_base;
              position: absolute;
              pointer-events: none;
            }
            @if $type ==sp {
              width: 23px;
              height: 23px;
              left: -32px;
              top: 16px;
            }
            @if $type ==pc {
              width: 32px;
              height: 32px;
              padding-bottom: 3px;
              left: -65px;
              top: 20px;
              margin-block: auto;
            }
          }
    
          >*:last-child {
            @if $type =="" {
              margin-bottom: 0;
            }
    
            @if $type ==sp {}
    
            @if $type ==pc {}
          }
        }
      }
    }
  }

  .bnrList {
    @if $type =="" {
    }
    @if $type ==sp {
      margin-top: 45px;
    }
    @if $type ==pc {
      display: flex;
      flex-wrap: wrap;
      margin-top: 138px;
    }

    > li {
      @if $type =="" {
      }
      @if $type ==sp {
        + li {
          margin-top: 10px;
        }
      }
      @if $type ==pc {
        width: calc(33.3% - 27px);

        &:not(:nth-child(3n)) {
          margin-right: 40px;
        }

        &:nth-child(n + 4) {
          margin-top: 40px;
        }
      }

      a {
        @if $type =="" {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
        }
        @if $type ==sp {
          aspect-ratio: 373/117;
        }
        @if $type ==pc {
          min-height: 140px;

          &:hover,
          &:focus-visible {
            .bnrListThumb img {
              transform: scale(1.1);
            }
          }
        }

        &:before {
          @if $type =="" {
            content: "";
            display: block;
            border: 1px solid #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            opacity: .3;
            pointer-events: none;
            z-index: 1;
          }
          @if $type ==sp {
            width: calc(100% - 6px);
            height: calc(100% - 6px);
          }
          @if $type ==pc {
            width: calc(100% - 14px);
            height: calc(100% - 14px);
          }
        }

        .bnrListThumb {
          @if $type =="" {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            overflow: hidden;
            z-index: 0;
          }
          @if $type ==sp {
          }
          @if $type ==pc {
            aspect-ratio: 373/139;
          }

          

          img {
            @if $type =="" {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            @if $type ==sp {
              object-position: top center;
            }
            @if $type ==pc {
              transition: .2s transform;
            }
          }
        }

        .bnrListText {
          @if $type =="" {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;
            height: 100%;
            color: #fff;
            position: relative;
            z-index: 2;
          }
          @if $type ==sp {
          }
          @if $type ==pc {
          }

          .bnrListTitleEn {
            @if $type =="" {
              @include fontEn;
              line-height: 1.4;
            }
            @if $type ==sp {
              font-size: 23px;
              letter-spacing: 0.08em
            }
            @if $type ==pc {
              font-size: 34px;
              letter-spacing: 0.01em
            };
          }

          .bnrListTitle {
            @if $type =="" {
            }
            @if $type ==sp {
              font-size: 11px;
              letter-spacing: 0.12em;
            }
            @if $type ==pc {
              font-size: 16px;
            }
          }
        }

      }

    }
  }
}

.heroCatchTitle,
.serviceContentTitle,
.contactTitle {
  @if $type=="" {
      &:not(.active) {
          opacity: 0;
      }
  }
  @if $type ==sp {
    transition: .8s opacity;
  }
  @if $type ==pc {
    transition: 1s .2s opacity;
  }

  &.active {
      @if $type=="" {
          opacity: 1;
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }
  }
}


.heroCatchStrong {
    @if $type=="" {

        &:not(.active) {
            opacity: 0;
        }
    }
    @if $type ==sp {
      transition: .6s .3s opacity, .6s .2s transform;

      &:not(.active) {
        transform:  translateY(15px);
      }
    }
    @if $type ==pc {
      transition: .6s .3s opacity, .6s .2s transform;

      &:not(.active) {
        transform:  translateY(30px);
      }
    }

    &.active {
        @if $type=="" {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.heroCatchNote {
  @if $type=="" {

      &:not(.active) {
          opacity: 0;
      }
  }
  @if $type ==sp {
    transition: .6s .4s opacity, .6s .4s transform;

    &:not(.active) {
      transform:  translateY(15px);
    }
  }
  @if $type ==pc {
      transition: .6s .4s opacity, .6s .4s transform;

      &:not(.active) {
        transform:  translateY(30px);
      }
  }

  &.active {
      @if $type=="" {
          opacity: 1;
          transform: scale(1);
      }
  }
}

.title {
  @if $type=="" {

      &:not(.active) {
          opacity: 0;
      }
  }
  @if $type ==sp {
    transition: .6s .3s opacity, .6s .3s transform;

    
    &:not(.active) {
      transform:  translateY(10px);
    }
  }
  @if $type ==pc {
      transition: 1s .1s opacity, 1s .1s transform;

      &:not(.active) {
        transform:  translateY(20px);
      }
  }

  &.active {
      @if $type=="" {
          opacity: 1;
          transform: scale(1);
      }
  }
}


// .titleSub {
//   @if $type=="" {
//     >span {
//       display: flex;
//       flex-direction: column;
//       overflow: hidden;

//       span {
//         transition: .4s transform;

//         &:after {
//           content: attr(data-text);
//           display: block;
//           background: rgb(232,83,143);
//           background: linear-gradient(270deg, rgba(232,83,143,1) 0%, rgba(196,31,99,1) 100%);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//         }
//       }
//     }

//   }
//   @if $type ==sp {
//     > span {
//       height: 1.2em;
//     }
//   }
//   @if $type ==pc {
//     > span {
//       height: 1.2em;
//     }
//   }

//   &.active {
//       @if $type=="" {
//        >span{
//         span {
//           transform: translateY(-1.4em);
//         }
//        }
//       }
//   }
// }

.titleSub {
  @if $type=="" {
    &:not(.active) {
        opacity: 0;
    }
  }
  @if $type ==sp {
    transition: 1s opacity;
  }
  @if $type ==pc {
    transition: 1.5s opacity;
  }

  &.active {
      @if $type=="" {
          opacity: 1;
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }
  }

}


.serviceContentThumb {
  @if $type=="" {
    &:not(.active) {
        opacity: 0;

        img {
          transform: scale(1.05);
        }
    }
  }
  @if $type ==sp {
    transition: 1s opacity;

    img {
      transition: 1s transform ease-in-out;
    }
  }
  @if $type ==pc {
    transition: 1.5s opacity;

    img {
      transition: 1s transform ease-in-out;
    }
  }

  &.active {
      @if $type=="" {
          opacity: 1;

          img {
            transform: scale(1);
          }
      }
      @if $type ==sp {
      }
      @if $type ==pc {
      }
  }

}