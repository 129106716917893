@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

#sectionHero .heroCatch {
  position: relative;
}
#sectionHero .heroCatch:before, #sectionHero .heroCatch:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  pointer-events: none;
}
#sectionHero .heroCatch:before {
  left: 0;
}
#sectionHero .heroCatch:after {
  right: 0;
}
#sectionHero .heroCatch .heroCatchText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#sectionHero .heroCatch .heroCatchTitle {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.03em;
  background: rgb(255, 114, 114);
  background: linear-gradient(90deg, rgb(255, 114, 114) 0%, rgb(196, 31, 99) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#sectionHero .heroCatch .heroCatchStrong {
  aspect-ratio: 204/32;
}
#sectionHero .heroCatch .heroCatchNote {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  color: #000000;
  font-weight: 500;
}

#sectionMc .titleHeader,
#sectionService .titleHeader,
#sectionVoice .titleHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#sectionMc .titleHeader .title,
#sectionService .titleHeader .title,
#sectionVoice .titleHeader .title {
  line-height: 1.2;
}
#sectionMc .titleHeader .titleSub,
#sectionService .titleHeader .titleSub,
#sectionVoice .titleHeader .titleSub {
  font-family: "Baskervville", sans-serif;
  line-height: 1.4;
  background: rgb(232, 83, 143);
  background: linear-gradient(270deg, rgb(232, 83, 143) 0%, rgb(196, 31, 99) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#sectionMc .mcLead {
  text-align: center;
}
#sectionMc .mcSlide {
  position: relative;
}
#sectionMc .mcSlide .splide__arrows .splide__arrow {
  border: none;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.2s filter;
}
#sectionMc .mcSlide .splide__arrows .splide__arrow:disabled {
  filter: grayscale(100%);
  cursor: inherit;
}
#sectionMc .mcSlide .splide__arrows .splide__arrow.splide__arrow--prev {
  transform: scale(-1, 1) translateY(-61px);
  left: 0;
}
#sectionMc .mcSlide .splide__arrows .splide__arrow.splide__arrow--next {
  transform: scale(1, 1) translateY(-61px);
  right: 0;
}
#sectionMc .mcSlide .splide__arrows .splide__arrow:before {
  content: "";
  display: block;
  background: url(../images2024/common/icon_arrow_big_pink.svg) no-repeat center;
  background-size: contain;
  background-position: right center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#sectionMc .mcSlide .splide__arrows .splide__arrow svg {
  display: none;
}
#sectionMc .mcSlide .splide__arrows .splide__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
#sectionMc .mcSlide .splide__pagination > li button {
  appearance: none;
  border: none;
  border-radius: 9999px;
  background: #d8d8d8;
}
#sectionMc .mcSlide .splide__pagination > li button.is-active {
  background: #c41f63;
}
#sectionMc .mcSlide .splide__slide {
  position: relative;
}
#sectionMc .mcSlide .splide__slide a {
  display: block;
}
#sectionMc .mcSlide .splide__slide a .mcSlideThumb {
  overflow: hidden;
}
#sectionMc .mcSlide .splide__slide a .mcSlideThumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#sectionMc .mcSlide .splide__slide a .mcSlideText .mcSlideName {
  font-weight: bold;
}
#sectionMc .mcSlide .splide__slide a .mcSlideText .mcSlideNameEn {
  font-family: "Baskervville", sans-serif;
  color: #c41f63;
  letter-spacing: 0.16em;
}
#sectionService {
  position: relative;
}
#sectionService .titleHeader .titleSub {
  line-height: 1.2;
}
#sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentThumb {
  overflow: hidden;
}
#sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentThumb img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
#sectionService .serviceContetntItem:not(:nth-child(1)) .serviceContentThumb {
  overflow: hidden;
}
#sectionService .serviceContetntItem .serviceContentText .serviceContentTitle {
  font-weight: bold;
}
#sectionVoice .voiceLead {
  text-align: center;
}
#sectionVoice .voiceSlide .splide__arrows .splide__arrow {
  border: none;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.2s filter;
}
#sectionVoice .voiceSlide .splide__arrows .splide__arrow:disabled {
  filter: grayscale(100%);
  cursor: inherit;
}
#sectionVoice .voiceSlide .splide__arrows .splide__arrow.splide__arrow--prev {
  transform: scale(-1, 1);
  left: 0;
}
#sectionVoice .voiceSlide .splide__arrows .splide__arrow.splide__arrow--next {
  transform: scale(1, 1);
  right: 0;
}
#sectionVoice .voiceSlide .splide__arrows .splide__arrow:before {
  content: "";
  display: block;
  background: url(../images2024/common/icon_arrow_big_pink.svg) no-repeat center;
  background-size: contain;
  background-position: right center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#sectionVoice .voiceSlide .splide__arrows .splide__arrow svg {
  display: none;
}
#sectionVoice .voiceSlide .splide__arrows .splide__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
#sectionVoice .voiceSlide .splide__pagination > li button {
  appearance: none;
  border: none;
  border-radius: 9999px;
  background: #fff;
}
#sectionVoice .voiceSlide .splide__pagination > li button.is-active {
  background: #c41f63;
}
#sectionVoice .voiceSlide .splide__slide .voiceListTitle {
  font-weight: bold;
}
#sectionVoice .voiceSlide .splide__slide .voiceListName {
  text-align: right;
  font-weight: 500;
}

#sectionBlog .titleHeader,
#sectionChannel .titleHeader,
#sectionFaq .titleHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#sectionBlog .titleHeader .title,
#sectionChannel .titleHeader .title,
#sectionFaq .titleHeader .title {
  line-height: 1.2;
}
#sectionBlog .titleHeader .titleSub,
#sectionChannel .titleHeader .titleSub,
#sectionFaq .titleHeader .titleSub {
  font-family: "Baskervville", sans-serif;
  line-height: 1.4;
  background: rgb(232, 83, 143);
  background: linear-gradient(270deg, rgb(232, 83, 143) 0%, rgb(196, 31, 99) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#sectionBlog .splide__arrows .splide__arrow {
  border: none;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.2s filter;
}
#sectionBlog .splide__arrows .splide__arrow:disabled {
  filter: grayscale(100%);
  cursor: inherit;
}
#sectionBlog .splide__arrows .splide__arrow.splide__arrow--prev {
  transform: scale(-1, 1) translateY(-77px);
  left: 0;
}
#sectionBlog .splide__arrows .splide__arrow.splide__arrow--next {
  transform: scale(1, 1) translateY(-77px);
  right: 0;
}
#sectionBlog .splide__arrows .splide__arrow:before {
  content: "";
  display: block;
  background: url(../images2024/common/icon_arrow_big_pink.svg) no-repeat center;
  background-size: contain;
  background-position: right center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#sectionBlog .splide__arrows .splide__arrow svg {
  display: none;
}
#sectionBlog .splide__arrows .splide__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
#sectionBlog .splide__pagination > li button {
  appearance: none;
  border: none;
  border-radius: 9999px;
  background: #d8d8d8;
}
#sectionBlog .splide__pagination > li button.is-active {
  background: #c41f63;
}
#sectionBlog .blogList > li a .blogListThumb {
  overflow: hidden;
}
#sectionBlog .blogList > li a .blogListThumb img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#sectionBlog .blogList > li a .blogListText .blogListDate {
  color: #888888;
}
#sectionBlog .blogList > li a .blogListText .blogListTitle {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

#sectionChannel .channelList > li {
  line-height: 1;
  overflow: hidden;
}
#sectionChannel .channelList > li iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

#sectionFaq .faqContent .qaList > li {
  border: 1px solid #cacaca;
  position: relative;
}
#sectionFaq .faqContent .qaList > li:before {
  content: "Q";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 9999px;
  line-height: 1;
  background: #c41f63;
  position: absolute;
  pointer-events: none;
}
#sectionFaq .faqContent .qaList > li .qaTitle button {
  appearance: none;
  display: block;
  width: 100%;
  border: none;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  background: none;
  color: #222222;
  text-align: left;
  cursor: pointer;
}
#sectionFaq .faqContent .qaList > li .qaTitle button[aria-expanded=true] .qaIcon {
  transform: rotate(-180deg);
}
#sectionFaq .faqContent .qaList > li .qaTitle button .qaIcon {
  display: block;
  background: url(../images2024/common/icon_arrow_anchor.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.2s transform;
}
#sectionFaq .faqContent .qaList > li .qaContent {
  border-top: 1px solid #cacaca;
  position: relative;
  transition: padding 0.3s, height 0.3s, opacity 0.3s, visibility 0.3s;
}
#sectionFaq .faqContent .qaList > li .qaContent[aria-hidden=true] {
  height: 0;
  opacity: 0;
  visibility: hidden;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#sectionFaq .faqContent .qaList > li .qaContent:before {
  content: "A.";
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.3em;
  color: #fff;
  border-radius: 9999px;
  line-height: 1;
  color: #c41f63;
  position: absolute;
  pointer-events: none;
}
#sectionFaq .faqContent .qaList > li .qaContent > *:last-child {
  margin-bottom: 0;
}
#sectionFaq .bnrList > li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#sectionFaq .bnrList > li a:before {
  content: "";
  display: block;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  opacity: 0.3;
  pointer-events: none;
  z-index: 1;
}
#sectionFaq .bnrList > li a .bnrListThumb {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  z-index: 0;
}
#sectionFaq .bnrList > li a .bnrListThumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#sectionFaq .bnrList > li a .bnrListText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
  color: #fff;
  position: relative;
  z-index: 2;
}
#sectionFaq .bnrList > li a .bnrListText .bnrListTitleEn {
  font-family: "Baskervville", sans-serif;
  line-height: 1.4;
}
.heroCatchTitle:not(.active),
.serviceContentTitle:not(.active),
.contactTitle:not(.active) {
  opacity: 0;
}
.heroCatchTitle.active,
.serviceContentTitle.active,
.contactTitle.active {
  opacity: 1;
}

.heroCatchStrong:not(.active) {
  opacity: 0;
}
.heroCatchStrong.active {
  opacity: 1;
  transform: scale(1);
}

.heroCatchNote:not(.active) {
  opacity: 0;
}
.heroCatchNote.active {
  opacity: 1;
  transform: scale(1);
}

.title:not(.active) {
  opacity: 0;
}
.title.active {
  opacity: 1;
  transform: scale(1);
}

.titleSub:not(.active) {
  opacity: 0;
}
.titleSub.active {
  opacity: 1;
}

.serviceContentThumb:not(.active) {
  opacity: 0;
}
.serviceContentThumb:not(.active) img {
  transform: scale(1.05);
}
.serviceContentThumb.active {
  opacity: 1;
}
.serviceContentThumb.active img {
  transform: scale(1);
}

@media screen and (max-width: 768.99px) {
  @keyframes splide-loading {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
  .splide__track--draggable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .splide__track--fade > .splide__list > .splide__slide {
    margin: 0 !important;
    opacity: 0;
    z-index: 0;
  }
  .splide__track--fade > .splide__list > .splide__slide.is-active {
    opacity: 1;
    z-index: 1;
  }
  .splide--rtl {
    direction: rtl;
  }
  .splide__track--ttb > .splide__list {
    display: block;
  }
  .splide__container {
    box-sizing: border-box;
    position: relative;
  }
  .splide__list {
    backface-visibility: hidden;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .splide.is-initialized:not(.is-active) .splide__list {
    display: block;
  }
  .splide__pagination {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    pointer-events: none;
  }
  .splide__pagination li {
    display: inline-block;
    line-height: 1;
    list-style-type: none;
    margin: 0;
    pointer-events: auto;
  }
  .splide:not(.is-overflow) .splide__pagination {
    display: none;
  }
  .splide__progress__bar {
    width: 0;
  }
  .splide {
    position: relative;
    visibility: hidden;
  }
  .splide.is-initialized, .splide.is-rendered {
    visibility: visible;
  }
  .splide__slide {
    backface-visibility: hidden;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    list-style-type: none !important;
    margin: 0;
    position: relative;
  }
  .splide__slide img {
    vertical-align: bottom;
  }
  .splide__spinner {
    animation: splide-loading 1s linear infinite;
    border: 2px solid #999;
    border-left-color: transparent;
    border-radius: 50%;
    bottom: 0;
    contain: strict;
    display: inline-block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
  }
  .splide__sr {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
    display: none;
  }
  .splide__toggle.is-active .splide__toggle__pause {
    display: inline;
  }
  .splide__track {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  #sectionHero .heroVisual img {
    width: 100%;
    max-width: inherit;
  }
  #sectionHero .heroCatch {
    padding-block: 57px 70px;
  }
  #sectionHero .heroCatch:before {
    width: 32.5vw;
    max-width: 244px;
    height: 460px;
    background: url(../images2024/home/catch_bg_left_sp.webp) no-repeat;
    background-size: contain;
  }
  #sectionHero .heroCatch:after {
    width: 54.67vw;
    max-width: 410px;
    height: 460px;
    background: url(../images2024/home/catch_bg_right_sp.webp) no-repeat;
    background-size: contain;
  }
  #sectionHero .heroCatch .heroCatchTitle {
    font-size: 42px;
  }
  #sectionHero .heroCatch .heroCatchStrong {
    margin-top: 22px;
    width: 120px;
    aspect-ratio: 120/19;
  }
  #sectionHero .heroCatch .heroCatchNote {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
    font-size: 14px;
    line-height: 2;
  }
  #sectionMc .titleHeader .title,
  #sectionService .titleHeader .title,
  #sectionVoice .titleHeader .title {
    margin-top: 6px;
    font-size: 13px;
  }
  #sectionMc .titleHeader .titleSub,
  #sectionService .titleHeader .titleSub,
  #sectionVoice .titleHeader .titleSub {
    font-size: 35px;
    letter-spacing: 0.02em;
  }
  #sectionMc .inner {
    padding-inline: 0;
    overflow: hidden;
  }
  #sectionMc .titleHeader {
    padding: 0 14px;
    margin: 0 auto;
  }
  #sectionMc .mcLead {
    max-width: 300px;
    padding: 0 15px;
    margin-top: 10px;
    margin-inline: auto;
    font-size: 14px;
    line-height: 1.75;
  }
  #sectionMc .mcSlide {
    margin-top: 25px;
  }
  #sectionMc .mcSlide .splide__arrows {
    display: none !important;
  }
  #sectionMc .mcSlide .splide__pagination {
    padding-inline: 15px;
    margin-top: 15px;
  }
  #sectionMc .mcSlide .splide__pagination > li {
    margin-inline: 4px;
  }
  #sectionMc .mcSlide .splide__pagination > li button {
    width: 7px;
    height: 7px;
  }
  #sectionMc .mcSlide .splide__slide {
    width: calc(47.73vw + 6px) !important;
    padding-inline: 3px;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideThumb {
    border-radius: 5px;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideText {
    margin-top: 13px;
    text-align: center;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideText .mcSlideName {
    font-size: 16px;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideText .mcSlideNameEn {
    margin-top: 2px;
    font-size: 11px;
  }
  #sectionMc .btnWrap {
    margin-top: 27px;
  }
  #sectionService {
    padding-block: 75px 90px;
    overflow: hidden;
  }
  #sectionService .serviceInner {
    padding: 0 14px;
    margin: 0 auto;
  }
  #sectionService .titleHeader .titleSub {
    font-size: 50px;
    letter-spacing: 0.04em;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentTitle {
    margin-top: 25px;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentThumb {
    margin-top: 14px;
    border-radius: 5px;
    aspect-ratio: 720/477;
  }
  #sectionService .serviceContetntItem + .serviceContetntItem {
    margin-top: 45px;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) .serviceContentThumb {
    margin-top: 14px;
    border-radius: 5px;
  }
  #sectionService .serviceContetntItem .serviceContentText .serviceContentTitle {
    font-size: 21px;
  }
  #sectionService .serviceContetntItem .serviceContentText .serviceContentTitle span {
    font-size: 12px;
  }
  #sectionService .serviceContetntItem .serviceContentText .serviceContentNote {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.85;
  }
  #sectionService .serviceContetntItem .serviceContentText * + .btnWrap {
    margin-top: 24px;
  }
  #sectionVoice {
    padding-block: 0 60px;
    padding-inline: 15px;
    background: url(../images2024/home/voice_bg_sp.webp) no-repeat center top;
    background-size: cover;
  }
  #sectionVoice .voiceLead {
    margin-top: 11px;
    font-size: 14px;
    line-height: 1.75;
  }
  #sectionVoice .voiceSlide {
    margin-top: 28px;
  }
  #sectionVoice .voiceSlide .splide__arrows {
    display: none !important;
  }
  #sectionVoice .voiceSlide .splide__track {
    overflow: visible;
  }
  #sectionVoice .voiceSlide .splide__pagination {
    margin-top: 15px;
  }
  #sectionVoice .voiceSlide .splide__pagination > li {
    margin-inline: 4px;
  }
  #sectionVoice .voiceSlide .splide__pagination > li button {
    width: 7px;
    height: 7px;
  }
  #sectionVoice .voiceSlide .splide__slide {
    padding: 20px 27px;
    border-radius: 5px;
    box-shadow: 3px 3px 17px rgba(255, 214, 218, 0.3);
    background: rgba(255, 255, 255, 0.7);
  }
  #sectionVoice .voiceSlide .splide__slide:nth-child(n+3) {
    display: none;
  }
  #sectionVoice .voiceSlide .splide__slide + .splide__slide {
    margin-top: 15px;
  }
  #sectionVoice .voiceSlide .splide__slide .voiceListTitle {
    font-size: 15px;
    line-height: 1.53;
  }
  #sectionVoice .voiceSlide .splide__slide .voiceListNote {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.7;
  }
  #sectionVoice .voiceSlide .splide__slide .voiceListName {
    margin-top: 7px;
    font-size: 12px;
  }
  #sectionBlog .titleHeader .title,
  #sectionChannel .titleHeader .title,
  #sectionFaq .titleHeader .title {
    margin-top: 5px;
    font-size: 13px;
  }
  #sectionBlog .titleHeader .titleSub,
  #sectionChannel .titleHeader .titleSub,
  #sectionFaq .titleHeader .titleSub {
    font-size: 35px;
  }
  #sectionBlog {
    padding-block: 82px 68px;
  }
  #sectionBlog .blogSlide {
    margin-top: 8px;
  }
  #sectionBlog .splide__arrows {
    display: none !important;
  }
  #sectionBlog .splide__pagination {
    margin-top: 15px;
  }
  #sectionBlog .splide__pagination > li {
    margin-inline: 4px;
  }
  #sectionBlog .splide__pagination > li button {
    width: 7px;
    height: 7px;
  }
  #sectionBlog .blogList > li + li {
    border-top: 1px solid #cacaca;
  }
  #sectionBlog .blogList > li a {
    display: flex;
    align-items: center;
    padding-block: 17px;
  }
  #sectionBlog .blogList > li a .blogListThumb {
    width: 40.29%;
    aspect-ratio: 278/170;
    border-radius: 5px;
  }
  #sectionBlog .blogList > li a .blogListText {
    width: 50.7%;
    margin-inline: auto;
  }
  #sectionBlog .blogList > li a .blogListText .blogListDate {
    font-size: 11px;
    letter-spacing: 0.1em;
  }
  #sectionBlog .blogList > li a .blogListText .blogListTitle {
    font-size: 14px;
    line-height: 1.57;
  }
  #sectionChannel {
    padding-block: 0 33px;
  }
  #sectionChannel .channelList {
    width: calc(100% - 50px);
    margin-inline: auto;
    margin-top: 30px;
  }
  #sectionChannel .channelList > li + li {
    margin-top: 16px;
  }
  #sectionChannel .channelList > li iframe {
    border-radius: 5px;
  }
  #sectionFaq {
    padding-block: 33px 67px;
  }
  #sectionFaq .faqContent .qaList {
    margin-top: 25px;
  }
  #sectionFaq .faqContent .qaList > li {
    border-radius: 10px;
  }
  #sectionFaq .faqContent .qaList > li:before {
    width: 23px;
    height: 23px;
    padding-bottom: 3px;
    left: 12px;
    top: 16px;
  }
  #sectionFaq .faqContent .qaList > li + li {
    margin-top: 10px;
  }
  #sectionFaq .faqContent .qaList > li .qaTitle button {
    padding: 16px 40px 16px 45px;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 500;
    transition: 0.2s opacity;
  }
  #sectionFaq .faqContent .qaList > li .qaTitle button .qaIcon {
    width: 12px;
    right: 15px;
  }
  #sectionFaq .faqContent .qaList > li .qaContent {
    width: calc(100% - 95px);
    margin-right: 40px;
    margin-left: 45px;
    padding: 16px 0 16px 0;
    font-size: 13px;
    line-height: 1.92;
  }
  #sectionFaq .faqContent .qaList > li .qaContent:before {
    width: 23px;
    height: 23px;
    left: -32px;
    top: 16px;
  }
  #sectionFaq .bnrList {
    margin-top: 45px;
  }
  #sectionFaq .bnrList > li + li {
    margin-top: 10px;
  }
  #sectionFaq .bnrList > li a {
    aspect-ratio: 373/117;
  }
  #sectionFaq .bnrList > li a:before {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
  }
  #sectionFaq .bnrList > li a .bnrListThumb img {
    object-position: top center;
  }
  #sectionFaq .bnrList > li a .bnrListText .bnrListTitleEn {
    font-size: 23px;
    letter-spacing: 0.08em;
  }
  #sectionFaq .bnrList > li a .bnrListText .bnrListTitle {
    font-size: 11px;
    letter-spacing: 0.12em;
  }
  .heroCatchTitle,
  .serviceContentTitle,
  .contactTitle {
    transition: 0.8s opacity;
  }
  .heroCatchStrong {
    transition: 0.6s 0.3s opacity, 0.6s 0.2s transform;
  }
  .heroCatchStrong:not(.active) {
    transform: translateY(15px);
  }
  .heroCatchNote {
    transition: 0.6s 0.4s opacity, 0.6s 0.4s transform;
  }
  .heroCatchNote:not(.active) {
    transform: translateY(15px);
  }
  .title {
    transition: 0.6s 0.3s opacity, 0.6s 0.3s transform;
  }
  .title:not(.active) {
    transform: translateY(10px);
  }
  .titleSub {
    transition: 1s opacity;
  }
  .serviceContentThumb {
    transition: 1s opacity;
  }
  .serviceContentThumb img {
    transition: 1s transform ease-in-out;
  }
}
@media print, screen and (min-width: 769.09px) {
  @keyframes splide-loading {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
  .splide__track--draggable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .splide__track--fade > .splide__list > .splide__slide {
    margin: 0 !important;
    opacity: 0;
    z-index: 0;
  }
  .splide__track--fade > .splide__list > .splide__slide.is-active {
    opacity: 1;
    z-index: 1;
  }
  .splide--rtl {
    direction: rtl;
  }
  .splide__track--ttb > .splide__list {
    display: block;
  }
  .splide__container {
    box-sizing: border-box;
    position: relative;
  }
  .splide__list {
    backface-visibility: hidden;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .splide.is-initialized:not(.is-active) .splide__list {
    display: block;
  }
  .splide__pagination {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    pointer-events: none;
  }
  .splide__pagination li {
    display: inline-block;
    line-height: 1;
    list-style-type: none;
    margin: 0;
    pointer-events: auto;
  }
  .splide:not(.is-overflow) .splide__pagination {
    display: none;
  }
  .splide__progress__bar {
    width: 0;
  }
  .splide {
    position: relative;
    visibility: hidden;
  }
  .splide.is-initialized, .splide.is-rendered {
    visibility: visible;
  }
  .splide__slide {
    backface-visibility: hidden;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    list-style-type: none !important;
    margin: 0;
    position: relative;
  }
  .splide__slide img {
    vertical-align: bottom;
  }
  .splide__spinner {
    animation: splide-loading 1s linear infinite;
    border: 2px solid #999;
    border-left-color: transparent;
    border-radius: 50%;
    bottom: 0;
    contain: strict;
    display: inline-block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
  }
  .splide__sr {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
    display: none;
  }
  .splide__toggle.is-active .splide__toggle__pause {
    display: inline;
  }
  .splide__track {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  #sectionHero .heroCatch {
    padding-block: 108px 73px;
  }
  #sectionHero .heroCatch:before, #sectionHero .heroCatch:after {
    width: 38.02vw;
    max-width: 730px;
    aspect-ratio: 1/1;
  }
  #sectionHero .heroCatch:before {
    background: url(../images2024/home/catch_bg_left.webp) no-repeat;
    background-size: contain;
  }
  #sectionHero .heroCatch:after {
    background: url(../images2024/home/catch_bg_right.webp) no-repeat;
    background-size: contain;
  }
  #sectionHero .heroCatch .heroCatchTitle {
    font-size: 83px;
  }
  #sectionHero .heroCatch .heroCatchStrong {
    margin-top: 30px;
    aspect-ratio: 204/32;
  }
  #sectionHero .heroCatch .heroCatchNote {
    margin-top: 40px;
    font-size: 20px;
    text-align: center;
  }
  #sectionMc .titleHeader .title,
  #sectionService .titleHeader .title,
  #sectionVoice .titleHeader .title {
    margin-top: 10px;
    font-size: 18px;
  }
  #sectionMc .titleHeader .titleSub,
  #sectionService .titleHeader .titleSub,
  #sectionVoice .titleHeader .titleSub {
    font-size: 60px;
    letter-spacing: 0.08em;
  }
  #sectionMc {
    padding-block: 68px 73px;
  }
  #sectionMc .inner {
    max-width: 1800px;
  }
  #sectionMc .mcLead {
    margin-top: 25px;
    font-size: 20px;
  }
  #sectionMc .mcSlide {
    padding-inline: 60px;
    margin-top: 50px;
  }
  #sectionMc .mcSlide .splide__arrows .splide__arrow {
    width: 40px;
    height: 40px;
  }
  #sectionMc .mcSlide .splide__arrows .splide__arrow:not(:disabled):focus:before, #sectionMc .mcSlide .splide__arrows .splide__arrow:not(:disabled):hover:before {
    transform: translateX(5px);
  }
  #sectionMc .mcSlide .splide__arrows .splide__arrow:before {
    width: 40px;
    height: 45px;
    transition: 0.2s transform;
  }
  #sectionMc .mcSlide .splide__pagination {
    margin-top: 22px;
  }
  #sectionMc .mcSlide .splide__pagination > li {
    margin-inline: 7px;
  }
  #sectionMc .mcSlide .splide__pagination > li button {
    width: 9px;
    height: 9px;
  }
  #sectionMc .mcSlide .splide__slide {
    padding-inline: 6px;
  }
  #sectionMc .mcSlide .splide__slide a:hover .mcSlideThumb img, #sectionMc .mcSlide .splide__slide a:focus-visible .mcSlideThumb img {
    transform: scale(1.1);
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideThumb {
    border-radius: 10px;
    aspect-ratio: 295/369;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideThumb img {
    transition: 0.2s transform;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideText {
    margin-top: 20px;
    text-align: center;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideText .mcSlideName {
    font-size: 17px;
  }
  #sectionMc .mcSlide .splide__slide a .mcSlideText .mcSlideNameEn {
    font-size: 16px;
  }
  #sectionService {
    padding-block: 73px 74px;
  }
  #sectionService .serviceInner {
    width: 100%;
    max-width: 1640px;
    padding-inline: 40px;
    margin-inline: auto;
  }
  #sectionService .titleHeader {
    align-items: flex-start;
  }
  #sectionService .titleHeader .title {
    font-size: 18px;
  }
  #sectionService .titleHeader .titleSub {
    font-size: 110px;
    letter-spacing: 0.02em;
  }
  #sectionService .serviceContetntItem {
    position: relative;
  }
  #sectionService .serviceContetntItem:nth-child(1) {
    margin-bottom: 50px;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40%;
    max-width: 480px;
    margin-right: 60%;
    margin-left: auto;
    min-height: 580px;
    letter-spacing: 0.04em;
    word-break: keep-all;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentTitle {
    margin-top: 50px;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentThumb {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-block: auto;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentThumb {
    display: flex;
    align-items: center;
    width: 56%;
    height: 516px;
    border-radius: 10px;
  }
  #sectionService .serviceContetntItem + .serviceContetntItem {
    margin-top: 55px;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) {
    display: flex;
    align-items: center;
    min-height: 366px;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(even) {
    padding-left: 50%;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(even) .serviceContentThumb {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-block: auto;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(odd) {
    padding-right: 50%;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(odd) .serviceContentThumb {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-block: auto;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) .serviceContentThumb {
    display: flex;
    align-items: center;
    width: 50%;
    min-height: 366px;
    border-radius: 10px;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) .serviceContentThumb img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) .serviceContentText {
    width: 59.35%;
    max-width: 440px;
    margin-inline: auto;
    padding-block: 20px;
  }
  #sectionService .serviceContetntItem .serviceContentText .serviceContentTitle {
    font-size: 30px;
    line-height: 1.2;
  }
  #sectionService .serviceContetntItem .serviceContentText .serviceContentTitle span {
    font-size: 16px;
  }
  #sectionService .serviceContetntItem .serviceContentText .serviceContentNote {
    margin-top: 18px;
    font-size: 17px;
    line-height: 2.35;
  }
  #sectionService .serviceContetntItem .serviceContentText * + .btnWrap {
    margin-top: 40px;
  }
  #sectionVoice {
    max-width: 1800px;
    padding: 75px 40px 70px;
    margin-inline: auto;
    background: url(../images2024/home/voice_bg.webp) no-repeat center bottom;
    background-size: cover;
  }
  #sectionVoice .voiceLead {
    margin-top: 27px;
    font-size: 20px;
  }
  #sectionVoice .voiceSlide {
    padding-inline: 57px;
    margin-top: 52px;
  }
  #sectionVoice .voiceSlide .splide__arrows .splide__arrow {
    width: 40px;
    height: 40px;
  }
  #sectionVoice .voiceSlide .splide__arrows .splide__arrow:not(:disabled):focus:before, #sectionVoice .voiceSlide .splide__arrows .splide__arrow:not(:disabled):hover:before {
    transform: translateX(5px);
  }
  #sectionVoice .voiceSlide .splide__arrows .splide__arrow:before {
    width: 40px;
    height: 45px;
    transition: 0.2s transform;
  }
  #sectionVoice .voiceSlide .splide__pagination {
    margin-top: 22px;
  }
  #sectionVoice .voiceSlide .splide__pagination > li {
    margin-inline: 7px;
  }
  #sectionVoice .voiceSlide .splide__pagination > li button {
    width: 9px;
    height: 9px;
  }
  #sectionVoice .voiceSlide .splide__slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(33.3% - 20px) !important;
    padding: 40px 45px 34px;
    margin-inline: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.6);
  }
  #sectionVoice .voiceSlide .splide__slide .voiceListTitle {
    min-height: 3em;
    font-size: 18px;
    line-height: 1.55;
  }
  #sectionVoice .voiceSlide .splide__slide .voiceListNote {
    margin-top: 23px;
    font-size: 16px;
    line-height: 2;
  }
  #sectionVoice .voiceSlide .splide__slide .voiceListName {
    padding-top: 14px;
    margin-top: auto;
    margin-left: auto;
    font-size: 16px;
  }
  #sectionBlog .titleHeader .title,
  #sectionChannel .titleHeader .title,
  #sectionFaq .titleHeader .title {
    margin-top: 20px;
    font-size: 18px;
  }
  #sectionBlog .titleHeader .titleSub,
  #sectionChannel .titleHeader .titleSub,
  #sectionFaq .titleHeader .titleSub {
    font-size: 40px;
  }
  #sectionBlog {
    padding: 114px 40px 114px;
    max-width: 1800px;
    margin-inline: auto;
    background: url(../images2024/home/blog_bg.webp) no-repeat center;
    background-size: cover;
  }
  #sectionBlog .inner {
    padding-inline: 0;
  }
  #sectionBlog .blogSlide {
    padding-inline: 50px;
    margin-top: 27px;
  }
  #sectionBlog .splide__arrows .splide__arrow {
    width: 40px;
    height: 40px;
  }
  #sectionBlog .splide__arrows .splide__arrow:not(:disabled):focus:before, #sectionBlog .splide__arrows .splide__arrow:not(:disabled):hover:before {
    transform: translateX(5px);
  }
  #sectionBlog .splide__arrows .splide__arrow:before {
    width: 40px;
    height: 45px;
    transition: 0.2s transform;
  }
  #sectionBlog .splide__pagination {
    margin-top: 22px;
  }
  #sectionBlog .splide__pagination > li {
    margin-inline: 7px;
  }
  #sectionBlog .splide__pagination > li button {
    width: 9px;
    height: 9px;
  }
  #sectionBlog .blogList > li {
    width: 33.3% !important;
    padding-inline: 15px;
  }
  #sectionBlog .blogList > li a {
    display: block;
  }
  #sectionBlog .blogList > li a:hover .blogListThumb img, #sectionBlog .blogList > li a:focus-visible .blogListThumb img {
    transform: scale(1.1);
  }
  #sectionBlog .blogList > li a .blogListThumb {
    aspect-ratio: 380/232;
    border-radius: 10px;
  }
  #sectionBlog .blogList > li a .blogListThumb img {
    transition: 0.2s transform;
  }
  #sectionBlog .blogList > li a .blogListText .blogListDate {
    margin-top: 20px;
    font-size: 15px;
    letter-spacing: 0.1em;
  }
  #sectionBlog .blogList > li a .blogListText .blogListTitle {
    margin-top: 2px;
  }
  #sectionChannel {
    padding-block: 0 57px;
  }
  #sectionChannel .titleHeader .titleSub {
    margin-top: 0;
  }
  #sectionChannel .channelList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  #sectionChannel .channelList > li {
    width: calc(50% - 23px);
  }
  #sectionChannel .channelList > li:nth-child(odd) {
    margin-right: auto;
  }
  #sectionChannel .channelList > li:nth-child(n+3) {
    margin-top: 46px;
  }
  #sectionChannel .channelList > li iframe {
    border-radius: 10px;
  }
  #sectionFaq {
    padding-block: 57px 110px;
  }
  #sectionFaq .faqContent {
    display: flex;
    justify-content: space-between;
  }
  #sectionFaq .faqContent .titleHeader {
    align-items: flex-start;
  }
  #sectionFaq .faqContent .qaList {
    width: 82.8%;
  }
  #sectionFaq .faqContent .qaList > li {
    border-radius: 15px;
    transition: 0.2s border-color;
  }
  #sectionFaq .faqContent .qaList > li:hover, #sectionFaq .faqContent .qaList > li:focus-visible {
    border-color: #c41f63;
  }
  #sectionFaq .faqContent .qaList > li:before {
    width: 32px;
    height: 32px;
    padding-bottom: 3px;
    left: 24px;
    top: 24px;
    margin-block: auto;
  }
  #sectionFaq .faqContent .qaList > li + li {
    margin-top: 14px;
  }
  #sectionFaq .faqContent .qaList > li .qaTitle button {
    padding: 28px 50px 28px 90px;
    font-size: 17px;
    font-weight: 500;
  }
  #sectionFaq .faqContent .qaList > li .qaTitle button .qaIcon {
    width: 12px;
    height: 12px;
    right: 25px;
  }
  #sectionFaq .faqContent .qaList > li .qaContent {
    width: calc(100% - 140px);
    padding: 20px 0 20px 0;
    margin-right: 50px;
    margin-left: 90px;
  }
  #sectionFaq .faqContent .qaList > li .qaContent:before {
    width: 32px;
    height: 32px;
    padding-bottom: 3px;
    left: -65px;
    top: 20px;
    margin-block: auto;
  }
  #sectionFaq .bnrList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 138px;
  }
  #sectionFaq .bnrList > li {
    width: calc(33.3% - 27px);
  }
  #sectionFaq .bnrList > li:not(:nth-child(3n)) {
    margin-right: 40px;
  }
  #sectionFaq .bnrList > li:nth-child(n+4) {
    margin-top: 40px;
  }
  #sectionFaq .bnrList > li a {
    min-height: 140px;
  }
  #sectionFaq .bnrList > li a:hover .bnrListThumb img, #sectionFaq .bnrList > li a:focus-visible .bnrListThumb img {
    transform: scale(1.1);
  }
  #sectionFaq .bnrList > li a:before {
    width: calc(100% - 14px);
    height: calc(100% - 14px);
  }
  #sectionFaq .bnrList > li a .bnrListThumb {
    aspect-ratio: 373/139;
  }
  #sectionFaq .bnrList > li a .bnrListThumb img {
    transition: 0.2s transform;
  }
  #sectionFaq .bnrList > li a .bnrListText .bnrListTitleEn {
    font-size: 34px;
    letter-spacing: 0.01em;
  }
  #sectionFaq .bnrList > li a .bnrListText .bnrListTitle {
    font-size: 16px;
  }
  .heroCatchTitle,
  .serviceContentTitle,
  .contactTitle {
    transition: 1s 0.2s opacity;
  }
  .heroCatchStrong {
    transition: 0.6s 0.3s opacity, 0.6s 0.2s transform;
  }
  .heroCatchStrong:not(.active) {
    transform: translateY(30px);
  }
  .heroCatchNote {
    transition: 0.6s 0.4s opacity, 0.6s 0.4s transform;
  }
  .heroCatchNote:not(.active) {
    transform: translateY(30px);
  }
  .title {
    transition: 1s 0.1s opacity, 1s 0.1s transform;
  }
  .title:not(.active) {
    transform: translateY(20px);
  }
  .titleSub {
    transition: 1.5s opacity;
  }
  .serviceContentThumb {
    transition: 1.5s opacity;
  }
  .serviceContentThumb img {
    transition: 1s transform ease-in-out;
  }
}
@media screen and (min-width: 769.09px) and (max-width: 1100.99px) {
  @keyframes splide-loading {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
  .splide__track--draggable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .splide__track--fade > .splide__list > .splide__slide {
    margin: 0 !important;
    opacity: 0;
    z-index: 0;
  }
  .splide__track--fade > .splide__list > .splide__slide.is-active {
    opacity: 1;
    z-index: 1;
  }
  .splide--rtl {
    direction: rtl;
  }
  .splide__track--ttb > .splide__list {
    display: block;
  }
  .splide__container {
    box-sizing: border-box;
    position: relative;
  }
  .splide__list {
    backface-visibility: hidden;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .splide.is-initialized:not(.is-active) .splide__list {
    display: block;
  }
  .splide__pagination {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    pointer-events: none;
  }
  .splide__pagination li {
    display: inline-block;
    line-height: 1;
    list-style-type: none;
    margin: 0;
    pointer-events: auto;
  }
  .splide:not(.is-overflow) .splide__pagination {
    display: none;
  }
  .splide__progress__bar {
    width: 0;
  }
  .splide {
    position: relative;
    visibility: hidden;
  }
  .splide.is-initialized, .splide.is-rendered {
    visibility: visible;
  }
  .splide__slide {
    backface-visibility: hidden;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    list-style-type: none !important;
    margin: 0;
    position: relative;
  }
  .splide__slide img {
    vertical-align: bottom;
  }
  .splide__spinner {
    animation: splide-loading 1s linear infinite;
    border: 2px solid #999;
    border-left-color: transparent;
    border-radius: 50%;
    bottom: 0;
    contain: strict;
    display: inline-block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
  }
  .splide__sr {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
    display: none;
  }
  .splide__toggle.is-active .splide__toggle__pause {
    display: inline;
  }
  .splide__track {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText {
    width: 100%;
    max-width: inherit;
    margin-right: inherit;
    min-height: inherit;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentThumb {
    position: inherit;
    top: inherit;
    bottom: inherit;
    right: inherit;
    margin-block: 30px 0;
  }
  #sectionService .serviceContetntItem:nth-child(1) .serviceContentText .serviceContentThumb {
    width: 100%;
    min-height: inherit;
    margin-top: 30px;
    aspect-ratio: 720/477;
  }
  #sectionService .serviceContetntItem + .serviceContetntItem {
    margin-top: 50px;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) {
    flex-direction: column;
    min-height: inherit;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(even) {
    padding-left: 0;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(even) .serviceContentThumb {
    position: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    margin-block: 30px 0;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(odd) {
    padding-right: 0;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)):nth-child(odd) .serviceContentThumb {
    position: inherit;
    top: inherit;
    bottom: inherit;
    right: inherit;
    margin-block: 30px 0;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) .serviceContentThumb {
    width: 100%;
    min-height: inherit;
    margin-top: 30px;
  }
  #sectionService .serviceContetntItem:not(:nth-child(1)) .serviceContentText {
    width: 100%;
    max-width: inherit;
    padding-block: 0;
  }
  #sectionVoice .voiceSlide .splide__slide {
    width: 100% !important;
    margin-inline: 0;
  }
  #sectionBlog .blogList > li {
    width: 50% !important;
    padding-inline: 15px;
  }
  #sectionFaq .faqContent .qaList {
    width: 72.8%;
  }
}